import { createTheme, ThemeOptions } from "@mui/material";
import { useEffect, useState } from "react";

export interface Themes {
    light: ThemeOptions;
    dark: ThemeOptions;
}

const defaultParams = {
    typography: {
        fontFamily: 'IndieFlower, sans-serif'
    }
}

const defaultThemes: Themes = {
    light: {
        ...defaultParams,
        palette: {
            mode: "light",
            primary: {
                main: '#3055c2',
            },
            background: {
                paper: '#f5f5f5',
                default: '#f0f0f5'
            },
        }
    },
    dark: {
        ...defaultParams,
        palette: {
            mode: "dark"
        }
    }
}

export const useTheme = () => {
    const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [themes, setThemes] = useState(defaultThemes);
    const theme = createTheme(isDarkMode ? themes.dark : themes.light)
    return { theme, isDarkMode, setIsDarkMode, themes, setThemes }
}