import React from "react";
import { createContext, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { ui } from "@euglena/compact";



export class UI<RootState, Chromosome> implements ui.UI {

    public context = createContext({} as Chromosome);

    private setState: ((state: RootState) => void) | undefined = undefined;

    constructor(App: React.ComponentType<{ state: RootState }>, initialState: RootState, chromosome: Chromosome) {
        const root = createRoot(document.getElementById("root")!);
        const AppComponent = () => {
            const [state, _setState] = useState<RootState>(initialState);
            useEffect(() => {
                this.setState = _setState;
                return () => { this.setState = undefined; }
            }, [_setState]);
            return <App state={state} />;
        }
        root.render(<this.context.Provider value={chromosome}><AppComponent /></this.context.Provider>);

    }

    public render(state: RootState) {
        this.setState?.(state);
    }
}

