import { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export interface HistoryBarProps {
  items: Array<{
    id: string;
    _createdAt: number;
  }>
  onItemSelect: (id: string) => void;
  selectedItem: string;
}

export const HistoryBar = ({ items, selectedItem, onItemSelect }: HistoryBarProps) => {
  const value = items.findIndex(item => item.id === selectedItem);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    onItemSelect(items[newValue].id);
  };
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {
          items.map((item, index) => (
            <Tab key={index} label={`${new Date(item._createdAt).toLocaleString()} (${index+1})`} />
          ))
        }
      </Tabs>
    </Box>
  );
};