import { sys } from "cessnalib";
import { Particle, vacuole } from "@euglena/compact";
import { RootState } from "../../state";

export class Memory {
    private vacuoleJs: vacuole.VacuoleJs;
    constructor(particles: Particle[], nucleus: vacuole.Nucleus) {
        this.vacuoleJs = new vacuole.VacuoleJs(particles, nucleus)
    }

    async getState(): Promise<RootState> {
        return sys.StaticTools.Array.getFirst(await this.vacuoleJs.read({ _class: "State" })) as unknown as RootState;
    }

    async saveState(state: RootState,) {
        return this.vacuoleJs.save(state, { _class: "State" });
    }
}