import { navigator } from "@euglena/compact";

export class Navigator extends navigator.Navigator {
    constructor(protected nucleus: navigator.Nucleus) { 
        super(nucleus);
        window.onpopstate = () => {
            this.nucleus.onPathSelect(window.location.pathname);
        }
    }
    navigateTo(path: string, reload: boolean = false) {
        if (reload) {
            window.location.pathname = path;
        } else {
            history.pushState(null, "", path);
        }
    }
}