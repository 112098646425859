
import { Particle } from "@euglena/compact";
import { Blog, Branch, Leaf, Page, TreePart } from "@cdlvsm/website.backend.lib";

import { BlogComponent } from "./blog";
import { BranchComponent } from "./branch";
import { LeafComponent } from "./leaf";
import { Grid } from '@mui/material';
import { RootState } from "../../../state";

export interface TreePartComponentProps {
    treePart: TreePart;
    currentPath: string;
    loggedIn: boolean;
    state: RootState["ui"];
}

export const TreePartComponent = ({ treePart, loggedIn, currentPath, state }: TreePartComponentProps) => {

    const getComponent = () => {
        if (Particle.is<Branch>(treePart, "Branch")) {
            return <BranchComponent branch={treePart} currentPath={currentPath} loggedIn={loggedIn} state={state} />
        }
        if (Particle.is<Blog>(treePart, "Blog")) {
            return <BlogComponent blog={treePart} currentPath={currentPath} loggedIn={loggedIn} state={state} />
        }
        if (Particle.is<Leaf>(treePart, "Leaf")) {
            return <LeafComponent leaf={treePart} loggedIn={loggedIn} state={state} />
        }
        return null;
    }

    return <Grid container sx={{
        position: 'relative',
    }}>
        <Grid item xs={12}
            sx={{
                position: 'absolute',
                top: "1em",
                right: "1em",
            }}>

        </Grid>
        <Grid item xs={12}>
            {getComponent()}
        </Grid>
    </Grid>;
}