import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { AccountCircle } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, TextField, Typography, Box, alpha, Menu, MenuItem } from "@mui/material";
import { IconModal } from "./utils/IconModal";
import { useContext } from "../../utils/use-context";
import MenuIcon from '@mui/icons-material/Home';

export interface MenuAppBarProps {
    user: any;
    rootProps?: {
      title?: string;
      iconURL?: string;
    }
  }
  
  export const MenuAppBar = ({ rootProps = {}, user }: MenuAppBarProps) => {
    const { iconURL, title } = rootProps;
    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const chromosome = useContext(); // Replace this with the actual context hook
    const theme = useTheme(); // Use the theme hook
  
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const onLogoutButtonClick = () => {
      chromosome.onLogoutButtonClick();
      handleClose();
    };
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      chromosome.onSearch(event.target.value);
    };
  
    const [newTitle, setNewTitle] = useState(title);
  
    useEffect(() => {
      if (title !== newTitle) {
        setNewTitle(title)
      }
    }, [title])
  
    const [iconModalOpen, setIconModalOpen] = useState(false)
  
    useEffect(() => {
      // Update favicon when iconURL changes
      if (iconURL) {
        // Find existing favicon link
        const faviconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  
        if (faviconLink) {
          // Update existing favicon
          faviconLink.href = iconURL;
        } else {
          // Create new favicon link if none exists
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = iconURL;
          document.head.appendChild(newLink);
        }
  
        // Also update apple-touch-icon if it exists
        const touchIcon = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
        if (touchIcon) {
          touchIcon.href = iconURL;
        }
      }
    }, [iconURL]);
  
    return (
      <AppBar position="static" sx={{ boxShadow: 10 }}>
        <IconModal open={iconModalOpen} onClose={() => setIconModalOpen(false)} url={iconURL} onSave={(url) => chromosome.onRootPropsChange({
          ...rootProps,
          iconURL: url
        })} />
        <Toolbar>
          {
            user && <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                if (user) {
                  setIconModalOpen(true);
                } else {
                  chromosome.onPathSelect('/')
                }
              }}
            >
              {iconURL && user ? (
                <img src={iconURL} alt="Website Icon" style={{ width: 24, height: 24 }} />
              ) : (
                <MenuIcon />
              )}
            </IconButton>
          }
          {
            user && <TextField
              margin="normal"
              fullWidth
              name="title"
              label="Title"
              type="text"
              value={newTitle}
              onChange={(event) => {
                setNewTitle(event.target.value);
              }}
              onBlur={() => {
                chromosome.onRootPropsChange({
                  ...rootProps,
                  title: newTitle || ""
                });
              }}
            />
          }
          {
            !user && <Typography sx={{ cursor: "pointer", minWidth: "fit-content" }} variant="h6" component="div" onClick={() => chromosome.onPathSelect('/')}>
              {title}
            </Typography>
          }
          <Box
            sx={{
              position: 'relative',
              borderRadius: 1, // Adjust this as per your theme's border radius
              backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.common.white, 0.25),
              },
              width: '100%',
              marginLeft: '2.3em',
              marginRight: '1.2em',
            }}
          >
            {/* <Box
              sx={{
                padding: (theme) => theme.spacing(0, 2),
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SearchIcon />
            </Box> */}
            {/* <InputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearchChange}
              value={searchValue}
              sx={{
                color: 'inherit',
                width: '100%',
                marginLeft: '3em',
                '& .MuiInputBase-input': {
                  padding: (theme) => theme.spacing(1, 1, 1, 0),
                  paddingLeft: `calc(1em + ${(theme:any) => theme.spacing(4)})`,
                  transition: (theme) => theme.transitions.create('width'),
                  width: '100%',
                  [theme.breakpoints.up('sm')]: {
                    width: '12ch',
                    '&:focus': {
                      width: '20ch',
                    },
                  },
                },
              }}
            /> */}
          </Box>
          {user && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Preferences</MenuItem>
                <MenuItem onClick={handleClose}>My Account</MenuItem>
                <MenuItem onClick={onLogoutButtonClick}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
  };