import React from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { TreePartName } from "@cdlvsm/website.backend.lib";

export interface AddTreePartModalProps {
    open: boolean;
    onClose: () => void;
    onTreePartAdd: (title: string, path: string, type: TreePartName) => void;
    types?: TreePartName[];
}

export const AddTreePartModal = ({ open, onClose, onTreePartAdd, types = ["Leaf", "Branch", "Blog"] }: AddTreePartModalProps) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [autoGenerate, setAutoGenerate] = React.useState(true);
    const [pageType, setPageType] = React.useState<TreePartName>("Leaf");
    const [title, setTitle] = React.useState("");
    const [path, setPath] = React.useState("");
    const [error, setError] = React.useState("");
    const getPathFromTitle = (title: string) => {
        const temp = title.toLowerCase().replace(/\s/g, "-");
        //remove special characters
        return temp.replace(/[^a-zA-Z0-9-]/g, "");
    }
    const getTitleFromPath = (path: string) => {
        return path.split("/").map(part => part[0].toUpperCase() + part.slice(1)).join(" ");
    }
    return <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card sx={style}>
            <CardHeader>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add New Page
                </Typography>
            </CardHeader>
            <CardContent>
                <Select
                    fullWidth
                    autoFocus
                    value={pageType}
                    label="Type"
                    required
                    title="Type"
                    onChange={(event) => setPageType(event.target.value as TreePartName)}
                >
                    {
                        types.map((type, index) => <MenuItem key={index} value={type}>{type}</MenuItem>)
                    }
                </Select>
                <TextField
                    margin="normal"
                    fullWidth
                    name="title"
                    label="Title"
                    type="text"
                    value={title}
                    onChange={(event) => {
                        setTitle(event.target.value);
                        if (autoGenerate) {
                            setPath(getPathFromTitle(event.target.value));
                        }
                    }}
                />
                <FormControlLabel
                    control={<Checkbox checked={autoGenerate} onChange={() => setAutoGenerate(!autoGenerate)} color="primary" />}
                    label="Auto-generate path"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="path"
                    label="Path"
                    name="path"
                    type="text"
                    value={path}
                    onChange={(event) => setPath(event.target.value)}
                    disabled={autoGenerate}
                />
                {
                    !autoGenerate &&
                    <Typography variant="caption">
                        Path must be unique
                    </Typography>
                }
            </CardContent>
            <CardActions sx={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button onClick={() => {
                    let _title = title;
                    let _path = path;
                    if (!title && !path) {
                        setError("Both title and path can not be empty at the same time.");
                        return;
                    }
                    if (!title && path) _title = getTitleFromPath(path);
                    if (title && !path) _path = getPathFromTitle(title);
                    onTreePartAdd(_title, "/" + _path, pageType);
                    onClose();
                }}>Add</Button>
            </CardActions>
        </Card>
    </Modal>;
}