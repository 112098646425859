import React, { useEffect } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Modal, TextField, Typography, Box } from '@mui/material';

export interface IconModalProps {
    open: boolean;
    onClose: () => void;
    onSave:(url?:string) => void;
    url?: string;
}

export const IconModal = ({ open, onClose, onSave, url }: IconModalProps) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [path, setPath] = React.useState(url);
    useEffect(()=>{
        if(url !== path) {
            setPath(path);
        }
    },[url]);
    return <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card sx={style}>
            <CardHeader>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Icon URL
                </Typography>
            </CardHeader>
            <CardContent>
                {path && (
                    <Box sx={{ 
                        width: '100%', 
                        height: 200, 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}>
                        <img 
                            src={path}
                            alt="Icon preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                )}
                <TextField
                    margin="normal"
                    fullWidth
                    id="path"
                    label="Path"
                    name="path"
                    type="text"
                    value={path}
                    onChange={(event) => setPath(event.target.value)}
                />
            </CardContent>
            <CardActions sx={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button onClick={() => {
                    onSave(path);
                    onClose();
                }}>Save</Button>
            </CardActions>
        </Card>
    </Modal>;
}