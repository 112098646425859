import { Euglena } from '..';
import { RootState, initialState } from '../../state';

export abstract class Chromosome {
    constructor(protected props: { euglena: Euglena }) { }

    protected async handleEvent(reducer: (state: RootState) => Promise<RootState>): Promise<void> {
        const state: RootState = await this.props.euglena.vacuoleJs.getState() || initialState;
        const nextState = await reducer(state);
        this.props.euglena.vacuoleJs.saveState(nextState);
        console.log(nextState);
        this.props.euglena.ui!.render(nextState);
    };
}