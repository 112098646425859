import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { Fab, Button, Box, } from '@mui/material';
import { RootState } from "../../../state";
import { TreePartComponent } from './tree-part';
import { useContext } from "../../utils/use-context";
import { AddTreePartModal } from './utils/AddTreePartModal';
import { HistoryBar } from "./utils/HistoryBar";
import { useEffect, useState } from 'react';
import { DarkModeSwitch } from "./DarkModeSwitch";
import { ThemeEditor } from "./ThemeEditor";
import { MenuAppBar } from "./MenuAppBar"
import { Copyright } from "./Copyright";
import { useTheme } from "./useTheme";

export default function Home({ ui, user }: { ui: RootState["ui"], user: any }) {
  
  const loggedIn = !!user;
  const chromosome = useContext();
  const [addTreePartModalOpen, setAddTreePartModalOpen] = useState(false);
  const {theme, isDarkMode, setIsDarkMode, themes, setThemes} = useTheme();  
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if(ui.pageTree) {
      setFirstRender(false);
      setThemes(ui.pageTree!.props?.themes as any);
    }
  }, [ui.pageTree])

  const onAddClick = () => {
    setAddTreePartModalOpen(true);
  }
  const onTreePartAdd = (title: string, path: string, type: "Leaf" | "Branch" | "Blog") => {
    chromosome.onTreePartAdd("", title, path, type);
  }
  const onPageTreeVersionSelect = (pageTreeId: string) => {
    chromosome.onPageTreeVersionSelect(pageTreeId);
  }
  const onPageTreeDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this version of page tree?")) {
      chromosome.onPageTreeDelete(ui.pageTree!.id);
    }
  }
  if(!ui.pageTree || firstRender) return null;
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main"  >
          <CssBaseline />
          <MenuAppBar user={user} rootProps={ui.pageTree.props} />
          {loggedIn && ui.pageTreeHistory && <HistoryBar items={ui.pageTreeHistory!} onItemSelect={onPageTreeVersionSelect} selectedItem={ui.pageTree.id} />}
          {loggedIn && <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: "1em",
            color: "gray"
          }}>
            <Typography style={{ paddingRight: "1em" }}>Remove this version of page tree</Typography>
            <Button size={"small"} color="warning" aria-label="edit" onClick={onPageTreeDelete}>
              <DeleteIcon />
            </Button>
          </Box>}
          {ui.pageTree.trunk && <TreePartComponent treePart={ui.pageTree.trunk} currentPath={ui.path} loggedIn={loggedIn} state={ui} />}
          {!ui.pageTree.trunk && <Fab size="small" color="primary" aria-label="add" onClick={onAddClick}>
            <AddIcon />
          </Fab>}
          <Copyright sx={{ mt: 8, mb: 4 }} />
          {loggedIn && <ThemeEditor isDarkMode={isDarkMode} themes={themes} onChange={setThemes} onSave={() => chromosome.onRootPropsChange({...ui.pageTree!.props, themes})} />}
          <DarkModeSwitch {...{ isDarkMode, setIsDarkMode }} />
        </Container>
      </ThemeProvider>
      <AddTreePartModal open={addTreePartModalOpen} onClose={() => setAddTreePartModalOpen(false)} onTreePartAdd={onTreePartAdd} />
    </>
  );
}