import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable,
    OnDragEndResponder
} from "react-beautiful-dnd";
import AddIcon from '@mui/icons-material/Add';
import { Tabs, Tab, Box, Fab, Typography, Divider } from '@mui/material';
import { TreePartComponent } from "./tree-part";
import { Branch, TreePart } from "@cdlvsm/website.backend.lib";
import React, { useState } from 'react';
import { reorder } from "../../../utils/array";
import { useContext } from "../../utils/use-context";
import { AddTreePartModal } from "./utils/AddTreePartModal";
import { getPathParts } from "../../../utils/path";
import { DeleteButton } from "./DeleteButton";
import { RootState } from "../../../state";

export type Item = {
    id: string;
    El: React.ElementType;
    children: React.ReactNode[];
};

export type DraggableListItemProps = {
    item: string;
    index: number;
    style: React.CSSProperties;
    onItemSelect: () => void;
};

const DraggableListItem = ({ item, index, style, onItemSelect }: DraggableListItemProps) => {
    return (
        <Draggable draggableId={`${index}-${item}`} index={index}>
            {(provided, snapshot) => (
                <p key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...provided.draggableProps.style,
                        ...style,
                        padding: 8,
                    }}
                    onClick={onItemSelect}
                >{item} </p>
            )}
        </Draggable>
    );
};

export type DraggableListProps = {
    items: string[];
    onDragEnd: OnDragEndResponder;
    selectedItemIndex: number;
    onItemSelect: (index: number) => void;
};

const DraggableList = React.memo(({ items, onDragEnd, selectedItemIndex, onItemSelect }: DraggableListProps) => {
    {/* make list print items horizontally */ }
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {items.map((item, index) => (
                            <DraggableListItem key={index} item={item} index={index} style={{
                                borderBottom: selectedItemIndex === index ? '4px solid lightblue' : '0px',
                            }} onItemSelect={() => onItemSelect(index)} />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export interface BranchComponentProps {
    branch: Branch;
    currentPath: string;
    loggedIn: boolean;
    state: RootState["ui"];
}

export const BranchComponent = ({ branch, currentPath, loggedIn, state }: BranchComponentProps) => {
    const getSelectedPart = () => {
        const paths = getPathParts(currentPath);
        return branch.parts.find(part => paths.includes(part.path)) || branch.parts[0];
    }
    const selectedPart: TreePart | undefined = getSelectedPart();

    const selectedPartIndex = selectedPart ? Object.values(branch.parts).findIndex(part => part.path === selectedPart.path) : undefined;
    const chromosome = useContext();
    const handleChange = (event: React.SyntheticEvent, newSelectedPartIndex: number) => {
        const path = Object.values(branch.parts)[newSelectedPartIndex].path;
        chromosome.onPathSelect(path);

    };
    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(branch.parts, source.index, destination.index);

        chromosome.onTreePartOrderChange(branch.path, newItems);
    };
    const onItemSelect = (index: number) => {
        const path = Object.values(branch.parts)[index].path;
        chromosome.onPathSelect(path);
    }
    const [addTreePartModalOpen, setAddTreePartModalOpen] = useState(false);
    const onAddClick = () => {
        setAddTreePartModalOpen(true);
    }
    const onTreePartAdd = (title: string, path: string, type: "Leaf" | "Branch" | "Blog") => {
        chromosome.onTreePartAdd(branch.path, title, path, type);
    }
    return <Box
        sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <Box>
            {
                loggedIn &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {
                        (selectedPartIndex !== undefined) && <DraggableList selectedItemIndex={selectedPartIndex} items={Object.values(branch.parts).map(part => part.title)} onDragEnd={onDragEnd} onItemSelect={onItemSelect} />
                    }
                    {branch.parts.length === 0 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingX: "1em",
                            height: "2.6em",
                            color: 'gray'
                        }}>
                            <Typography>Remove this menu completely</Typography>
                            <DeleteButton onDelete={() => chromosome.onTreePartDelete(branch)} />
                            <Divider orientation="vertical" flexItem />
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingX: "1em",
                        color: 'gray'
                    }}>
                        <Typography>Add new item to the menu</Typography>
                        <Fab style={{ marginLeft: "1em" }} size="small" color="primary" aria-label="add" onClick={onAddClick} title="Add new item to the menu."><AddIcon /></Fab>
                    </Box>
                </Box>
            }
            {
                !loggedIn && selectedPartIndex !== undefined &&
                <Tabs value={selectedPartIndex} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    {
                        Object.values(branch.parts).map((part, index) => <Tab key={index} label={part.title} />)
                    }

                </Tabs>
            }
        </Box>
        {
            selectedPart && <TreePartComponent treePart={selectedPart} currentPath={currentPath} loggedIn={loggedIn} state={state} />
        }
        <AddTreePartModal open={addTreePartModalOpen} onClose={() => setAddTreePartModalOpen(false)} onTreePartAdd={onTreePartAdd} />
    </Box>;
};