import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RootState } from "../state";
import Login from "./scenes/login";
import Home from "./scenes/home";
import "./App.css";
import { Notification } from "./Notification";
import { useEffect } from 'react';

export interface AppProps {
    state: RootState
}

export default ({ state }: AppProps) => {
    useEffect(() => {
        document.title = state.ui.title;
    }, [state.ui.title]);
    return (
        <>
            {
                state.ui.notification && <Notification {...state.ui.notification} />
            }
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login user={state.user} />} />
                    <Route path="*" element={<Home ui={state.ui} user={state.user} />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}