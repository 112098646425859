import { Editor } from '@cdlvsm/tinymce-react.dist';
import { Box } from '@mui/material';

export interface TinyMceProps {
    value: string;
    onChange: (value: string) => void;
}

export function TinyMce({ value, onChange }: TinyMceProps) {
    const example_image_upload_handler = (blobInfo: any, progress: any) => new Promise((resolve, reject) => {
        // const xhr = new XMLHttpRequest();
        // xhr.withCredentials = false;
        // xhr.open('POST', 'postAcceptor.php');

        // xhr.upload.onprogress = (e) => {
        //     progress(e.loaded / e.total * 100);
        // };

        // xhr.onload = () => {
        //     if (xhr.status === 403) {
        //         reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        //         return;
        //     }

        //     if (xhr.status < 200 || xhr.status >= 300) {
        //         reject('HTTP Error: ' + xhr.status);
        //         return;
        //     }

        //     const json = JSON.parse(xhr.responseText);

        //     if (!json || typeof json.location != 'string') {
        //         reject('Invalid JSON: ' + xhr.responseText);
        //         return;
        //     }

        //     resolve(json.location);
        // };

        // xhr.onerror = () => {
        //     reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        // };

        // const formData = new FormData();
        // formData.append('file', blobInfo.blob(), blobInfo.filename());

        // xhr.send(formData);
        reject("Not implemented yet!")
    });
    return (
        <Box sx={{
            padding: 2,
        }}>
            <Editor
                init={{
                    height: 500,
                    menubar: true,
                    promotion: false,
                    branding: false,
                    inline: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: '',
                    file_picker_types: 'file image media',
                    images_file_types: 'jpg,svg,webp',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_upload_handler: example_image_upload_handler,
                } as any}
                value={value}
                onEditorChange={onChange}
            />
        </Box>
    );
}