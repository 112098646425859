
export type Method = "get" | "put" | "post" | "delete";

export type Native = object | string | boolean | number;

export interface Nucleus {
    onPathSelect(path: string): void;
}

export abstract class Navigator {
    constructor(protected nucleus: Nucleus) { }
    abstract navigateTo(path: string, reload: boolean): void;
}
