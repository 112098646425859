export class Particle<T extends string = string> {
    public _createdAt = new Date().getTime();
    constructor(public _class: T) { }

    public static is<InferredType extends Particle>(value: unknown, _class: InferredType["_class"]): value is InferredType {
        return typeof value === "object"
            && value !== null
            && "_class" in value
            && value._class === _class;
    }
}

export class Log extends Particle<"Log"> {
    constructor(public message: string, public level: "Info" | "Warning" | "Error") {
        super("Log");
    }
}

export class Exception extends Particle<"Exception"> {
    constructor(public message: string, public innerException?: Exception) {
        super("Exception");
    }
    public static isException(value: unknown): value is Exception {
        return Particle.is(value, "Exception");
    }
    private static _tryCatchErrorHandler: (error: unknown) => Exception = (error: unknown) => {
        if (Exception.isException(error)) {
            return error;
        }
        //check if the error is object
        if (typeof error !== "object" || error === null) {
            return new Exception(String(error));
        } else if ("message" in error) {
            return new Exception((error as any).message);
        } else if ("toString" in error) {
            return new Exception(error.toString());
        } else {
            try {
                return new Exception(JSON.stringify(error));
            } catch {
                //if the error is not serializable
                //Put object keys in the message
                const message = "Unknown error: keys: " + Object.keys(error).join(", ");
                return new Exception(message);
            }
        }
    }
    public static tryCatch<T>(callback: () => T): T | Exception{
        try {
            return callback();
        } catch (error: unknown) {
            return Exception._tryCatchErrorHandler(error);
        }
    }

    public static async tryCatchPromise<T>(promise: Promise<T>): Promise<T | Exception> {
        try {
            return await promise;
        } catch (error: unknown) {
            return Exception._tryCatchErrorHandler(error);
        }
    }
    
}

export class ACK extends Particle<"ACK"> {
    constructor() {
        super("ACK");
    }
}

export class State extends Particle<"State"> {
    constructor() {
        super("State");
    }
}

export class Token extends Particle<"Token"> {
    constructor(public encryptedToken: string,
        public decryptedToken: {
            euglenaName: string,
            createdAt: number,
            expireAt: number,
            type: string,
            roles: string[],
            status: string
        }) {
        super("Token");
    }
}

export class EuglenaName extends Particle<"EuglenaName"> {
    constructor(public euglenaName: string) {
        super("EuglenaName");
    }
}

export class EuglenaInfo extends Particle<"EuglenaInfo"> {

    constructor(
        public euglenaName: string,
        public roles: string[],
        public status: string,
        public info: {
            type: "Human" | "App",
            email: string,
            name: string,
            surname: string,
            birthdate: number,
            pictureUrl: string
        },
        public id: string,
    ) {
        super("EuglenaInfo");
    }

    public static fromJSON(json: any): EuglenaInfo {
        return new EuglenaInfo(
            json.euglenaName,
            json.roles,
            json.status,
            json.info,
            json.id
        );
    }

}