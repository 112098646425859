import { UI } from "@euglena/compact.react";
import App from "../../ui/App";
import { RootState, initialState } from "../../state";
import { UIChromosome } from "../chromosome/ui.chr";


export class UIOrganelle extends UI<RootState, UIChromosome> {
    constructor(uiChromosome: UIChromosome) {
        super(App, initialState, uiChromosome);
    }
}
