import DeleteIcon from '@mui/icons-material/Delete';

import { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

export interface DeleteButtonProps {
    onDelete: () => void;
  }
  
  export const DeleteButton = ({ onDelete }: DeleteButtonProps) => {
    const [open, setOpen] = useState(false);
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    return <Box>
      <Button color="warning" aria-label="edit" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <CardContent>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Page
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this page?
            </Typography>
          </CardContent>
          <CardActions sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={()=> {
              setOpen(false);
              onDelete();
            }}>Delete</Button>
          </CardActions>
        </Card>
      </Modal>
    </Box>;
  }