import { useState } from "react";
import {
  Fab,
  Modal,
  Card,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Themes } from "./useTheme";

export const ThemeEditor = ({
  isDarkMode,
  themes,
  onChange,
  onSave,
}: {
  isDarkMode: boolean,
  themes: any;
  onChange: (themes: Themes) => void;
  onSave: () => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedColorKey, setSelectedColorKey] = useState<"main" | "paper" | "background">("main");

  const mode = isDarkMode?"dark":"light";
  const handleColorChange = (newColor: string) => {
    switch (selectedColorKey) {
      case "background":   
      case "paper":
        onChange({
          ...themes,
          [mode]:{
            ...themes[mode],
            palette: {
              ...themes[mode].palette,
              background: {
                ...themes[mode].palette.background,
                [selectedColorKey === "paper" ? "paper" : "default"]: newColor
              }
            }
          }
        })
        break;
      case "main":
        onChange({
          ...themes,
          [mode]:{
            ...themes[mode],
            palette: {
              ...themes[mode].palette,
              primary: {
                main: newColor
              }
            }
          }
        })
        break;
    }
  };

  const getColor = (key: string) => {
    if(key === "background") return themes[mode].palette.background.default;
    if(key === "paper") return themes[mode].palette.background.paper;
    if(key === "main") return themes[mode].palette.primary.main;
  }

  const handleApply = () => {
    onSave();
    setModalOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 50,
        left: 0,
        margin: 10,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <Fab onClick={() => setModalOpen(true)}>
        <ColorLensIcon fontSize="large" color="primary" />
      </Fab>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="h2">
                Theme Editor
              </Typography>
            }
          />
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {["main", "paper", "background"].map((key) => (
                <TextField
                  key={key}
                  label={`Color for ${key}`}
                  value={getColor(key)}
                  onClick={() => setSelectedColorKey(key as "main" | "paper" | "background")}
                  InputProps={{
                    style: {
                      backgroundColor: getColor(key),
                    },
                  }}
                />
              ))}
              <HexColorPicker color={getColor(selectedColorKey)} onChange={handleColorChange} />
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
            <Button variant="contained" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
};
