import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props} component="div">
        {'Built with '}
        <Link color="inherit" href="https://website.codeloves.me">
          CodeLoves.Me Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }