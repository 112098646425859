
export const shiftOneItemDown = <T>(array: Array<T>, item: T) => {
    const index = array.indexOf(item);

    array.splice(index, 1);

    if (index === array.length) {
        array.push(item);
    } else {
        array.splice(index + 1, 0, item);
    }

    return array;
}

export const shiftOneItemUp = <T>(arr: Array<T>, item: T) => {
    const index = arr.indexOf(item);

    if (index > 0) {
        arr.splice(index, 1);
        arr.splice(index - 1, 0, item);
    }

    return arr;
}

export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};