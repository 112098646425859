import { Alert, Snackbar } from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import { Notification as Notif } from "../state";
import { useContext } from "./utils/use-context";

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

export const Notification = ({ message, severity }: Notif) => {
    const chromosome = useContext();
    const handleClose = () => {
        chromosome.onNotificationClose();
    }
    return <Snackbar
        open
        autoHideDuration={6000}
        TransitionComponent={SlideTransition}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
        <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
}