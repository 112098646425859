import {sys} from "cessnalib";
import { Token, vacuole } from "@euglena/compact";
import { VacuoleLocalstorage } from "@euglena/compact.localstorage";

import { isTokenValid } from "../../utils";

export class LocalStorage {
  private vacuoleLocalstorage: VacuoleLocalstorage;
  constructor(nucleus: vacuole.Nucleus) {
    this.vacuoleLocalstorage = new VacuoleLocalstorage(nucleus);
  }

  async getToken(): Promise<Token | undefined> {
    const token = sys.StaticTools.Array.getFirst(await this.vacuoleLocalstorage.read({ _class: "Token" })) as Token | undefined;
    return isTokenValid(token) ? token : undefined;
  }

  async saveToken(token: Token) {
    return await this.vacuoleLocalstorage.save(token, { _class: "Token" });
  }

  async removeToken() {
    return await this.vacuoleLocalstorage.remove({ _class: "Token" }, "All");
  }
}