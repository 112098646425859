import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Leaf } from "@cdlvsm/website.backend.lib";

import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { Blog } from "@cdlvsm/website.backend.lib";
import PageIcon from "@mui/icons-material/Description";
import { LeafComponent } from "./leaf";
import { useContext } from '../../utils/use-context';
import { getPathParts } from "../../../utils/path";
import { useState } from 'react';
import { AddTreePartModal } from './utils/AddTreePartModal';
import { RootState } from '../../../state';

export interface BlogComponentProps {
    blog: Blog;
    currentPath: string;
    loggedIn: boolean;
    state: RootState["ui"];
}

export const BlogComponent = ({ blog, currentPath, loggedIn, state }: BlogComponentProps) => {
    const getSelectedLeaf = () => {
        const paths = getPathParts(currentPath);
        return blog.parts.find(part => paths.includes(part.path)) || blog.parts[0];
    }
    const selectedLeaf: Leaf | undefined = getSelectedLeaf();
    const chromosome = useContext();
    const onPageSelect = (path: string) => {
        chromosome.onPathSelect(path);
    }
    const [addTreePartModalOpen, setAddTreePartModalOpen] = useState(false);
    const onAddClick = () => {
        setAddTreePartModalOpen(true);
    }
    const onTreePartAdd = (title: string, path: string) => {
        chromosome.onTreePartAdd(blog.path, title, path, "Leaf");
    }
    return (
        <>
            <Grid container>
                <Grid item xs={10}>
                    {
                        selectedLeaf && <LeafComponent leaf={selectedLeaf} loggedIn={loggedIn} state={state} />
                    }
                </Grid>
                <Grid item xs={2}>
                    <List dense={true}>
                        {
                            Object.values(blog.parts).map((leaf, index) => <ListItemButton key={index} selected={leaf.path === selectedLeaf.path} onClick={() => onPageSelect(leaf.path)}>
                                <ListItemIcon>
                                    <PageIcon fontSize="large" />
                                </ListItemIcon>
                                <ListItemText primary={leaf.title} secondary={new Date(leaf._createdAt).toLocaleDateString()} />
                            </ListItemButton>)
                        }
                    </List>
                </Grid>
            </Grid>
            {loggedIn && <Box sx={{
                '& > :not(style)': { m: 1 },
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                float: 'right',
                color: 'gray'
            }}>
                <Typography>Add a new blog page</Typography>
                <Fab color="primary" aria-label="add" onClick={onAddClick}>
                    <AddIcon />
                </Fab>
                <AddTreePartModal open={addTreePartModalOpen} onClose={() => setAddTreePartModalOpen(false)} onTreePartAdd={onTreePartAdd} types={["Leaf"]} />
            </Box>}
        </>
    );
}
