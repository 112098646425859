import * as cessnalib from "cessnalib";
import { Exception } from "../particle";

export type Nucleus  = {
    onSecondChanged?: (time: cessnalib.sys.Time)=> Promise<void>;
    onMinuteChanged?:(time: cessnalib.sys.Time)=>Promise<void>;
    onHourChanged?:(time: cessnalib.sys.Time)=>Promise<void>;
    onDayChanged?:(time: cessnalib.sys.Time)=>Promise<void>;
    onMonthChanged?:(time: cessnalib.sys.Time)=>Promise<void>;
    onYearChanged?:(time: cessnalib.sys.Time)=>Promise<void>;
}

export class Timer {
    constructor(private time: cessnalib.sys.Time, private nucleus: Nucleus) {}

    async getAlive(): Promise<void | Exception> {
        setInterval(() => {
            let newDate = new Date();
            if (newDate.getSeconds() != this.time.clock.second) {
                this.time.clock.second = newDate.getSeconds();
                this.nucleus.onSecondChanged?.(this.time);
                if (newDate.getMinutes() != this.time.clock.minute) {
                    this.time.clock.minute = newDate.getMinutes();
                    this.nucleus.onMinuteChanged?.(this.time);
                    if (newDate.getHours() != this.time.clock.hour) {
                        this.time.clock.hour = newDate.getHours();
                        this.nucleus.onHourChanged?.(this.time);
                        if (newDate.getDate() != this.time.date.day) {
                            this.time.date.day = newDate.getDate();
                            this.nucleus.onDayChanged?.(this.time);
                            if (newDate.getMonth() + 1 != this.time.date.month) {
                                this.time.date.month = newDate.getMonth() + 1;
                                this.nucleus.onMonthChanged?.(this.time);
                                if (newDate.getFullYear() != this.time.date.year) {
                                    this.time.date.year = newDate.getFullYear();
                                    this.nucleus.onYearChanged?.(this.time);
                                }
                            }
                        }
                    }
                }
            }
        }, 1000);
    }

    async setTime(time: cessnalib.sys.Time): Promise<void | Exception> {
        this.time = time;
    }

    async readTime(): Promise<cessnalib.sys.Time | Exception> {
        return this.time;
    }
}