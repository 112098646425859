import { Page, PageHistory, PageTree, PageTreeHistory, WebsiteBackend } from "@cdlvsm/website.backend.lib"
import { ACK, Exception, httpClient } from "@euglena/compact";

export class Backend implements WebsiteBackend {
    async search(value: string) {
        return await this.httpClient.get(`/search/${value}`) as any;
    }
    constructor(private httpClient: httpClient.HttpClient) { }
    // #region page-tree
    async getPageTreeById(pageTreeId: any, token?: string | undefined) {
        return await this.httpClient.get(`/page-tree/${pageTreeId}`, { authorization: `Bearer ${token}` }) as PageTree | Exception;
    }
    async getLatestPageTreeByDomain(domain: string): Promise<PageTree | Exception> {
        return await this.httpClient.get(`/page-tree/latest/${domain}`) as PageTree | Exception;
    }
    async createPageTree(pageTree: PageTree, token?: string): Promise<PageTree | Exception> {
        return await this.httpClient.post("/page-tree", pageTree, { authorization: `Bearer ${token}`, "content-type": "application/json" }) as PageTree | Exception;
    }
    async deletePageTreeById(id: string, encryptedToken?: string | undefined) {
        return await this.httpClient.delete(`/page-tree/${id}`, { authorization: `Bearer ${encryptedToken}` }) as ACK | Exception;
    }
    async getPageTreeHistoryByDomain(domain: string, token?: string): Promise<PageTreeHistory | Exception> {
        return await this.httpClient.get(`/page-tree/history/${domain}`, { authorization: `Bearer ${token}` }) as PageTreeHistory | Exception;
    }
    // #endregion
    // #region page
    async getPageById(id: string, token?: string): Promise<Page | Exception> {
        return await this.httpClient.get(`/page/${id}`, { authorization: `Bearer ${token}` }) as Page | Exception;
    }
    async getLatestPageBySequenceId(sequenceId: string): Promise<Page | Exception> {
        return await this.httpClient.get(`/page/latest/${sequenceId}`) as Page | Exception;
    }
    async createPage(page: Page, token?: string): Promise<Page | Exception> {
        return await this.httpClient.post(`/page`, page, { authorization: `Bearer ${token}`, "content-type": "application/json" }) as Promise<Page | Exception>;
    }
    async deletePagesBySequenceId(sequenceId: string, token?: string): Promise<ACK | Exception> {
        return await this.httpClient.delete(`/page?sequenceId=${sequenceId}`, { authorization: `Bearer ${token}` }) as Promise<ACK | Exception>;
    }
    async deletePageById(id: string, encryptedToken?: string | undefined) {
        return await this.httpClient.delete(`/page/${id}`, { authorization: `Bearer ${encryptedToken}` }) as ACK | Exception;
    }
    async getPageHistoryBySequenceId(sequenceId: string, token?: string): Promise<PageHistory | Exception> {
        return await this.httpClient.get(`/page/history/${sequenceId}`, { authorization: `Bearer ${token}` }) as PageHistory | Exception;
    }
    async getPageTreeWithHistory(domain: string, token: string): Promise<{ pageTree: PageTree, pageTreeHistory: PageTreeHistory } | Exception> {
        const pageTree = await this.getLatestPageTreeByDomain(domain);
        if (Exception.isException(pageTree)) return pageTree;
        const pageTreeHistory = await this.getPageTreeHistoryByDomain(domain, token);
        if (Exception.isException(pageTreeHistory)) return pageTreeHistory;
        return { pageTree, pageTreeHistory };
    }
    async getPageWithHistory(sequenceId: string, token?: string): Promise<{ page: Page, pageHistory: PageHistory } | Exception> {
        const page = await this.getLatestPageBySequenceId(sequenceId);
        if (Exception.isException(page)) return page;
        const pageHistory = await this.getPageHistoryBySequenceId(sequenceId, token);
        if (Exception.isException(pageHistory)) return pageHistory;
        return { page, pageHistory };
    }
    // #endregion
}